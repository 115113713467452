<template>
  <div class="address">
    <template v-if="zoneCode">
      <div class="text1" :class="{'mb-10' : zoneCode}">
        <input type="text" class="form-control border-focus-point font-sm" placeholder="기본 주소" :value="`${zoneCode ? `(${zoneCode}) ${address1}` : ''}`" readonly :disabled="disabled"/>
        <span class="pointer reset-btn" @click="removeAddress()" title="초기화" v-if="zoneCode && !disabled">&times;</span>
      </div>
      <input :id="id" type="text" class="form-control border-focus-point font-sm" placeholder="상세 주소" :value="address2" @input="onChangeAddress($event)" @keyup.enter="typeof enter === 'function' && enter()" ref="address2Ref"
             autocomplete="off" :disabled="disabled"/>
    </template>
    <button :id="id" class="btn btn-default border-focus-point btn-block font-sm" @click="openPost()" ref="buttonRef" :disabled="disabled" v-else>주소를 검색하시려면 이곳을 클릭하세요.</button>
  </div>
</template>

<script>
import {defineComponent, nextTick, ref} from "@vue/composition-api";
import store from "@/scripts/store";
import mixin from "@/scripts/mixin";

function Component(initialize) {
  this.name = "componentAddress";
  this.initialize = initialize;
  this.checkDuplicatedName = true;
}

export default defineComponent({
  mixins: [mixin],
  props: {
    id: String,
    zoneCode: String,
    address1: String,
    address2: String,
    enter: Function,
    disabled: Boolean
  },
  setup(props, {emit}) {
    const component = new Component(() => {
    });

    const address2Ref = ref();
    const buttonRef = ref();

    const openPost = () => {
      return store.commit("openModal", {
        name: "PostCode",
        callback: `${component.name}.onSelect`,
      });
    };

    const onSelect = (data) => {
      emit("update:zoneCode", data.zonecode);
      emit("update:address1", data.fullAddress);
      emit("update:address2", null);

      nextTick(() => {
        address2Ref.value.focus();
        store.commit("setSwingMessage", "상세 주소를 입력해주세요.");
      });
    };

    const removeAddress = () => {
      emit("update:zoneCode", null);
      emit("update:address1", null);
      emit("update:address2", null);
    };

    const onChangeAddress = (e) => {
      emit("update:address2", e.target.value);
    };

    return {component, address2Ref, buttonRef, openPost, onSelect, removeAddress, onChangeAddress};
  }
});
</script>

<style lang="scss" scoped>
.address {
  input[type=text] {
    height: $formHeight;
  }

  .text1 {
    position: relative;

    input {
      padding-right: $px35;
    }

    span {
      position: absolute;
      top: 50%;
      right: $px12;
      opacity: 0.7;
      transition: opacity 0.18s;
      transform: translateY(-50%);

      &:hover {
        opacity: 1;
      }
    }
  }

  > .btn {
    padding-top: 0;
    border-color: #ced4da;
    padding-bottom: 0;
    line-height: $px38;
    height: $formHeight;
  }
}
</style>