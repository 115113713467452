<template>
  <div class="confetti">
    <canvas ref="canvasRef"></canvas>
  </div>
</template>

<script>
import {defineComponent, onBeforeUnmount, onMounted, ref} from "@vue/composition-api";
import store from "@/scripts/store";
import {create} from "canvas-confetti";
import mixin from "@/scripts/mixin";

function Component(initialize) {
  this.name = "componentConfetti";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  props: {
    finish: Function
  },
  setup(props) {
    const component = new Component(() => {
      store.commit("addListener", [component.name, "onResize", () => {
        if (requestId) {
          cancelAnimationFrame(requestId);
        }

        if (myConfetti) {
          myConfetti.reset();
        }
      }]);
    });

    let requestId, myConfetti;
    const canvasRef = ref();
    const colors = ["27caa1", "27caa1"];
    const end = Date.now() + (5 * 1000);

    onMounted(() => {
      const run = () => {
        myConfetti({
          particleCount: 2,
          angle: 60,
          spread: 55,
          origin: {x: 0},
          colors: colors
        });

        myConfetti({
          particleCount: 2,
          angle: 120,
          spread: 55,
          origin: {x: 1},
          colors: colors
        });

        if (Date.now() < end) {
          requestId = requestAnimationFrame(run);
        } else if (typeof props.finish === "function") {
          setTimeout(() => {
            myConfetti.reset();
            props.finish();
          }, 3500);
        }
      };

      myConfetti = create(canvasRef.value, {
        resize: true,
        useWorker: true
      });

      run();
    });

    onBeforeUnmount(() => {
      window.cancelAnimationFrame(requestId);
    });

    return {component, canvasRef};
  }
});
</script>

<style lang="scss" scoped>
.confetti {
  width: 100%;
  height: 100%;

  canvas {
    width: 100%;
    height: 100%;
  }
}
</style>